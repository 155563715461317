<template>
  <div
    v-if="collateralFAssetIsActive"
    class="legend flex"
    :class="{'shim-height' : !collateralFAssetIsActive}"
  >
    <MintCollateralLegendItem
      :percentage="networkCollateralAllocation[1].set"
      :name="networkCollateralAllocation[1].ticker"
      :color="constant.BLUE"
    >
      <Fasset 
        class="small-logo"
      />
    </MintCollateralLegendItem>

    <MintCollateralLegendItem 
      :percentage="networkCollateralAllocation[2].set"
      :name="activeNetwork.ticker"
      :color="constant.PURPLE_XLIGHT"
    >
      <ActiveNetworkLogo 
        class="small-logo"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :flrSize="1.0"
        :flrColor="constant.WHITE"
        :sgbSize="0.6"
        :sgbColor="constant.WHITE"
      />
    </MintCollateralLegendItem>

    <MintCollateralLegendItem 
      :percentage="networkCollateralAllocation[0].set"
      :name="networkCollateralAllocation[0].ticker"
    >
      <Stable 
        class="small-logo"
      />
    </MintCollateralLegendItem>
    
    <div 
      class="legend-bg"
      :class="{ 'legend-default mobile' : !collateralFAssetIsActive }"
    >
    </div>
  </div>
  <div
    v-else
    class="legend flex"
    :class="{'shim-height' : !collateralFAssetIsActive}"
  >
    <MintCollateralLegendItem 
      :percentage="networkCollateralAllocation[1].set"
      :name="activeNetwork.ticker"
      :color="constant.PURPLE_XLIGHT"
    >
      <ActiveNetworkLogo 
        class="small-logo"
        :constant="constant"
        :activeNetwork="activeNetwork"
        :flrSize="1.0"
        :flrColor="constant.WHITE"
        :sgbSize="0.6"
        :sgbColor="constant.WHITE"
      />
    </MintCollateralLegendItem>
    <MintCollateralLegendItem 
      :percentage="networkCollateralAllocation[0].set"
      :name="networkCollateralAllocation[0].ticker"
      :color="constant.WHITE"
    >
      <Stable 
        class="small-logo"
      />
    </MintCollateralLegendItem>
    <div 
      class="legend-bg"
      :class="{ 'legend-default mobile' : !collateralFAssetIsActive }"
    >
    </div>
  </div>
</template>
<script setup>
  import MintCollateralLegendItem from './MintCollateralLegendItem.vue'
  import ActiveNetworkLogo from '../_icons/Network/ActiveNetworkLogo.vue'
  import Fasset from '../_icons/Fasset.vue'
  import Stable from '../_icons/Stable.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const constant = props.constant
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .legend {
    position: relative;
    justify-content: space-around;
    width: 85%;
    margin: 0 auto 20px auto;

    @include breakpoint(lg) {
      height: 300px;
      width: auto;
      margin: 0 0 0 150px;
      flex-direction: column;
    }
    @include breakpoint(xl) {
      height: 300px;
      width: auto;
      margin: 0 0 0 150px;
      flex-direction: column;
    }
    @include breakpoint(xxl) {
      height: 300px;
      width: auto;
      margin: 0 0 0 150px;
      flex-direction: column;
    }
    @include breakpoint(xxxl) {
      height: 300px;
      width: auto;
      margin: 0 0 0 150px;
      flex-direction: column;
    }

    &.shim-height { 
      @include breakpoint(lg) {
        height: 200px;
      }
      @include breakpoint(xl) {
        height: 200px;
      }
      @include breakpoint(xxl) {
        height: 200px;
      }
      @include breakpoint(xxxl) {
        height: 200px;
      }
    }

    .small-logo {
      margin-right: 5px;
    }

    .legend-bg {
      position: absolute;
      z-index: 0;
      top: 30px;
      width: 100%;
      height: 33px;
      background: $BLUE;
      background: linear-gradient(90deg, $BLUE 14%, $PURPLE-XLIGHT 43%, $PURPLE-XLIGHT 59%, $WHITE 81%);

      @include breakpoint(lg) {
        position: relative;
        background: transparent;
      }
      @include breakpoint(xl) {
        position: relative;
        background: transparent;
      }
      @include breakpoint(xxl) {
        position: relative;
        background: transparent;
      }
      @include breakpoint(xxxl) {
        position: relative;
        background: transparent;
      }

      &.legend-default {
        background: $WHITE;
        background: linear-gradient(90deg,  $PURPLE-XLIGHT 14%, $PURPLE-XLIGHT 43%, $WHITE 59%, $WHITE 81%);
      }
    }
  }
</style>