<template>
    <div 
      id="get-started" 
      class="page"
    >
      <Carousel 
        :constant="constant"
        :screensCarousel="screensCarousel"
        :activeNetwork="activeNetwork"
      />

      <h1>Simulate how FAsset minting on the <span class="">{{ activeNetwork.name }}</span> can impact <span class="color-purple-xxlight">{{ activeNetwork.ticker }}'s</span>&nbsp;market&nbsp;price.<span class="title-xxxsmall">*</span></h1>

      <AnimatedButton 
        :label="'Let\'s go'"
        :event="constant.MINTING"
        @nextScreen="nextScreen($event)"
      />
      <div class="disclaimer">
        <p ><span class="title-small">*</span><span class="txt-uppercase">Disclaimer:</span> This content is not intended as financial advice or financial guidance in any way, shape, or form. I am <span class="txt-uppercase">not</span> a financial advisor. This content is intended for entertainment&nbsp;purposes&nbsp;only.</p>
        <p>I have a passion for Finance and specialize in Front-End and UI (user interface) web development. I’ve been a Flare Network Community member since inception and saw an opportunity for a tool like SimFasset to exist. I’m now seizing the moment by launching this app to the world just as the FAsset system debuts on the Flare&nbsp;Network.</p>
        <p>Please note that SimFasset is a <span class="txt-uppercase">SIMULATOR</span>, it does <span class="txt-uppercase">not</span> facilitate the actual minting of assets on either the Flare or Songbird Networks. It does <span class="txt-uppercase">not</span> support “Wallet Connect” or any way to connect your various crypto wallet(s); there is no risk to you or your digital assets when using the application. SimFasset is 100% free, built by a community member for the community; its purpose is to educate and assist those who find&nbsp;it&nbsp;useful.</p>
      </div>
      <AppFooter
        :appSpecs="appSpecs"
      />
    </div>
</template>
<script setup>
  import { event } from "vue-gtag"
  import Carousel from './Carousel.vue'
  import AnimatedButton from '../_generics/AnimatedButton.vue'
  import AppFooter from '../App/AppFooter/AppFooter'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    activeNetwork: {
      type: Object,
      default: () => {}
    },
    screensCarousel: {
      type: Array,
      default: () => {}
    },
    appSpecs: {
      type: Object,
      default: () => {}
    },
  })
  const constant = props.constant
  const emit = defineEmits([
    'nextScreen',
  ])

  const nextScreen = (event) => {
    trackEvent('lets_go', 'lets_go_btn', 'lets_go_click')
    emit('nextScreen', constant.MINTING)
  }

  const trackEvent = (eventType, eventCategory, eventLabel) => {
    event(eventType, {
      'event_category': eventCategory,
      'event_label': eventLabel
    })
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .page {
    h1 {
      margin-top: 20px;
      padding: 0;
      text-transform: initial;
      margin: 30px auto 40px auto;
      max-width: 80%;
      font-weight: normal;

      @include breakpoint(sm) {
        max-width: 70%;
      }
      @include breakpoint(md) {
        max-width: 60%;
      }
      @include breakpoint(lg) {
        max-width: $MAX-CONTENT-WIDTH;
      }
      @include breakpoint(xl) {
        max-width: $MAX-CONTENT-WIDTH;
      }
      @include breakpoint(xxl) {
        max-width: $MAX-CONTENT-WIDTH;
      }
      @include breakpoint(xxxl) {
        max-width: $MAX-CONTENT-WIDTH;
      }
      
      .title-xxxsmall {
        color: $GREY-XXLIGHT;
        vertical-align: top;
        line-height: $FONT-XXXXLARGE;
      }
    }

    .disclaimer {
      margin: 80px auto 32px auto;
      color: $GREY-XXLIGHT;
      max-width: 90%;
      text-align: center;

      @include breakpoint(sm) {
        max-width: 90%;
      }
      @include breakpoint(md) {
        max-width: 90%;
      }
      @include breakpoint(lg) {
        max-width: calc($MAX-CONTENT-WIDTH - 100px);
        text-align: justify;
      }
      @include breakpoint(xl) {
        max-width: calc($MAX-CONTENT-WIDTH - 100px);
        text-align: justify;
      }
      @include breakpoint(xxl) {
        max-width: calc($MAX-CONTENT-WIDTH - 100px);
        text-align: justify;
      }
      @include breakpoint(xxxl) {
        max-width: calc($MAX-CONTENT-WIDTH - 100px);
        text-align: justify;
      }

      p {
        font-size: $FONT-XXSMALL;
        margin: 20px 0;
        
        .title-small {
          vertical-align: top;
          line-height: $FONT-XLARGE;
          padding-right: 1px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

    }

    .sub-header {
      margin-bottom: 10px;
    }
  }
</style>