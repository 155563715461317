<template>
  <div 
    class="flex row-first"
    :class="classes(null)"
  >   
    <FTickerIcon 
      :constant="constant"
      :coin="coin" 
      :width="48"
      :height="49"
      :size="0.5"
      class="icon" 
    />
    <span>F{{ coin.ticker }}</span>
  </div>
  <div 
    class="flex"
    :class="classes(mPriceColor)"
  >
    ${{ currencyTruncator(mPrice) }}
  </div>
  <div 
    class="flex"
    :class="classes(null)"
  >
    {{ currencyTruncator(qCollateral) }}
  </div>
  <div 
    class="flex desktop"
    :class="classes(null)"
  >
    {{ supply }}%
  </div>
  <div 
    class="flex desktop"
    :class="classes(null)"
  >
    ${{ currencyTruncator($collateral) }}
  </div>
  <div 
    class="flex row-last"
    :class="classes(null)"
  >
    ${{ currencyTruncator(collateralRequired) }}
  </div>
</template>
<script setup>
  import { computed } from 'vue'
  import FTickerIcon from '../_icons/Tickers/FTickerIconManager.vue'

  const props = defineProps({
    constant: {
      type: Object,
      default: () => {}
    },
    coin: {
      type: Object,
      default: () => {}
    },
    lastCoinId: {
      type: Number,
      default: 0
    },
    configs: {
      type: Object,
      default: () => {}
    },
    collateralFAssetIsActive: {
      type: Boolean,
      default: false
    },
    networkCollateralAllocation: {
      type: Array,
      default: () => []
    },
  })

  const constant = props.constant

  const classes = (value) => {
    if(props.coin.id === props.lastCoinId) {
      if(value === mPriceColor.value) {
        return { 
          [`last-full-row`] : true,
          'color-purple-xxlight' : mPriceColor.value
        }
      }
      let clas = { [`last-full-row`] : true }
      return clas
    }
    return ''
  }

  const mPrice = computed(() => {
    return props.coin.modifiedPrice > 0 ? props.coin.modifiedPrice : props.coin.price
  })

  const $collateral = computed(() => {
    return (parseFloat(props.coin.mintQuantity * mPrice.value) * getConfigsSettingById(constant.SETTINGS_MINT_COLLATERAL_RATE_ID).set)
  })

  const qCollateral = computed(() => {
    return parseFloat(props.coin.mintQuantity)
  })

  const collateralRequired = computed(() => {
    if(props.collateralFAssetIsActive) {
      return $collateral.value * props.networkCollateralAllocation[2].set
    }
    return $collateral.value * props.networkCollateralAllocation[1].set
  })

  const supply = computed(() => {
    return ((props.coin.mintQuantity / props.coin.circulatingSupply) * 100).toFixed(3)
  })

  const mPriceColor = computed(() => {
    return props.coin.modifiedPrice > 0
  })

  const currencyFormatter = (value, decimalCount = 2) => {
    return value.toFixed(decimalCount).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  }

  const currencyTruncator = (value) => {
    let newValue = parseInt(value)
    let len = newValue.toString().length

    if(len >= 5 && len < 7)
      return newValue = `${currencyFormatter(value/1000, 3)}K`
    else if(len >= 7 && len < 10)
      return newValue = `${currencyFormatter(value/1000000, 3)}M`
    else if(len >= 10 && len <= 12) 
      return newValue=`${currencyFormatter(value/1000000000, 3)}B`
    else if(len > 12) 
      return newValue=`${currencyFormatter(value/1000000000000, 3)}T`
    
    newValue = value
    return newValue = currencyFormatter(newValue)
  }
  const getConfigsSettingById = (settingId) => {
    const setting = props.configs.settings.find((setting) => setting.id === settingId)
    return setting
  }
</script>
<style lang="scss" scoped>
  @import '../../assets/scss/_variables.scss';
  @import '../../assets/scss/mixins/layout.scss';

  .flex {
    justify-content: center;
    background-color: $BLACK-LIGHTX;
    padding: 20px 0px;
    margin-bottom: 5px;
    color: $WHITE;
    font-size: $FONT-XXSMALL;
    color: $GREY-XLIGHT;

    @include breakpoint(sm) {
      font-size: $FONT-XXSMALL;
    }
    @include breakpoint(md) {
      font-size: $FONT-XSMALL;
    }
    @include breakpoint(lg) {
      font-size: $FONT-DEFAULT-SIZE;
    }
    @include breakpoint(xl) {
      font-size: $FONT-DEFAULT-SIZE;
    }
    @include breakpoint(xxl) {
      font-size: $FONT-DEFAULT-SIZE;
    }
    @include breakpoint(xxxl) {
      font-size: $FONT-DEFAULT-SIZE;
    }

    .icon {
      margin-left: 5px;

      @include breakpoint(md) {
        margin-left: 15px;
        height: 33px;
        width: 33px;
      }
      @include breakpoint(lg) {
        margin-left: 15px;
        height: 33px;
        width: 33px;
      }
      @include breakpoint(xl) {
        margin-left: 15px;
        height: 33px;
        width: 33px;
      }
      @include breakpoint(xxl) {
        margin-left: 15px;
        height: 33px;
        width: 33px;
      }
      @include breakpoint(xxxl) {
        margin-left: 15px;
        height: 33px;
        width: 33px;
      }
    }

    span {
      margin-left: 6px;

      @include breakpoint(md) {
        margin-left: 20px;
      }
      @include breakpoint(lg) {
        margin-left: 20px;
      }
      @include breakpoint(xl) {
        margin-left: 20px;
      }
      @include breakpoint(xxl) {
        margin-left: 20px;
      }
      @include breakpoint(xxxl) {
        margin-left: 20px;
      }
    }

    &.desktop {
      display: none;

      @include breakpoint(sm) {
        display: flex;
      }
      @include breakpoint(md) {
        display: flex;
      }
      @include breakpoint(lg) {
        display: flex;
      }
      @include breakpoint(xl) {
        display: flex;
      }
      @include breakpoint(xxl) {
        display: flex;
      }
      @include breakpoint(xxxl) {
        display: flex;
      }
    }

    &.row-first {
      justify-content: flex-start;
      font-size: $FONT-DEFAULT-SIZE;
      padding-left: 5px;
      color: $WHITE;

      @include breakpoint(sm) {
        font-size: $FONT-LARGE;
      }
      @include breakpoint(md) {
        font-size: $FONT-LARGE;
      }
      @include breakpoint(lg) {
        font-size: $FONT-XLARGE;
      }
      @include breakpoint(xl) {
        font-size: $FONT-XLARGE;
      }
      @include breakpoint(xxl) {
        font-size: $FONT-XLARGE;
      }
      @include breakpoint(xxxl) {
        font-size: $FONT-XLARGE;
      }
    }

    &.row-last {
      justify-content: flex-end;
      padding-right: 10px;
      color: $WHITE;

      @include breakpoint(sm) {
        padding-right: 20px;
      }
      @include breakpoint(md) {
        padding-right: 20px;
      }
      @include breakpoint(lg) {
        padding-right: 20px;
      }
      @include breakpoint(xl) {
        padding-right: 20px;
      }
      @include breakpoint(xxl) {
        padding-right: 20px;
      }
      @include breakpoint(xxxl) {
        padding-right: 20px;
      }
    }

    &.last-full-row {
      margin-bottom: 0;
    }
  }
</style>